import React, { useState, useEffect } from 'react'
import { navigate, graphql } from 'gatsby'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import copy from 'copy-to-clipboard'
import { getStoredCity, signUp, log, toCssPrefix, showToast } from '@ecommerce/shared'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import ConfirmationPage from '../../components/SignUp/ConfirmationPage'
import { formatRedirectURL, extractPageData } from '../../components/SignUp/utils'
import { SignupPage } from '../../types/PgPages'
import useQuery from '../../hooks/useQuery'
import { RichTextJson } from '../../graphql/contentfulTypes'

const { cssPrefix, toPrefix } = toCssPrefix('SignupConfirmation__')
const Wrapper = styled.div`
  .${cssPrefix} {
    &coupon {
      margin-top: 30px;
      font-size: 18px;
      line-height: 22px;

      p {
        font-weight: 400;
        margin: 0;
      }

      &-code {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
        width: 170px;
        height: 30px;
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.primary};
        border: 0;
        padding: 0;
        border-radius: ${({ theme }) => theme.borderRadius};
        margin: 25px auto;
        cursor: pointer;
        letter-spacing: 3.5px;
      }

      &-expire-date {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
`

const giftCouponQuery = `query SignupGiftCoupon($pageId: String!) {
  pgPage(id: $pageId) {
    template {
      ... on TmSignup {
        bottomText {
          json
        }
        giftCouponCode
        giftCouponExpireDate
      }
    }
  }
}
`
type GiftCouponQueryResult = {
  pgPage: {
    template: {
      bottomText?: RichTextJson
      giftCouponCode?: string
      giftCouponExpireDate?: string
    }
  }
}

const SignupConfirmationPage = ({ location, data, pageContext }: SignupPage) => {
  log.trace(pageContext.signupConfirmationId)
  const [isLoading, setIsLoading] = useState(false)
  const [reSendCount, setResendCount] = useState(0)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const storedCity = getStoredCity()
  const formData = location?.state?.formData
  const locationEmail = location?.state?.formData?.email || new URLSearchParams(location.search).get('email')

  const { pageData, template: pageTemplate } = extractPageData(data)

  useEffect(() => {
    return () => {
      window.history.replaceState(null, '')
    }
  }, [])

  const onResendEmail = async () => {
    if (typeof formData !== `undefined`) {
      try {
        if (!executeRecaptcha) throw Error('cannot execute Recaptcha')
        setIsLoading(true)

        const {
          firstName,
          lastName,
          dni,
          email,
          password,
          city,
          birthdate,
          dniComplement,
          documentType,
          billingName,
        } = formData
        const reCaptchaToken = await executeRecaptcha('signup')
        const signUpData = {
          firstName,
          lastName,
          rut: dni,
          email,
          password,
          city,
          birthdate,
          dniComplement,
          documentType,
          billingName,
          redirectUrl: formatRedirectURL(Number(city)),
          reCaptchaToken,
        }
        log.trace({ signUpData })

        await signUp(signUpData)

        setResendCount(reSendCount + 1)
        showToast({ content: 'Correo enviado exitosamente', type: 'success' })
      } catch (error) {
        showToast({ title: 'Upps!', content: 'Ha habido un error, por favor intenta de nuevo', type: 'error' })
      } finally {
        setIsLoading(false)
      }
    }
  }

  const { data: queryData } = useQuery<GiftCouponQueryResult>(giftCouponQuery, {
    variables: { pageId: pageContext.signupConfirmationId },
  })
  const giftCouponData = queryData?.pgPage?.template

  const onCopyCouponCode = () => {
    copy(giftCouponData?.giftCouponCode ?? '')

    showToast({ content: 'Enlace copiado exitosamente', type: 'success' })
  }

  const bottomComponent =
    giftCouponData?.bottomText && giftCouponData.giftCouponCode ? (
      <div className={toPrefix('coupon')}>
        <div className={toPrefix('coupon-text')}>{documentToReactComponents(giftCouponData.bottomText.json)}</div>
        <button onClick={onCopyCouponCode} type="button" title="Copiar código" className={toPrefix('coupon-code')}>
          {giftCouponData.giftCouponCode}
        </button>
        <span className={toPrefix('coupon-expire-date')}>{giftCouponData?.giftCouponExpireDate}</span>
      </div>
    ) : null

  return (
    <Wrapper className={cssPrefix}>
      <ConfirmationPage
        pageTitle={pageData?.title}
        bgImage={pageTemplate?.backgroundImage?.file?.url ?? ''}
        icon={pageTemplate?.icon?.file?.url ?? ''}
        title={pageTemplate?.pageHeading ?? ''}
        text={pageTemplate?.subtitle?.replace('$email', locationEmail ?? 'null') ?? ''}
        infoText={pageTemplate?.infoText}
        topButtonText="Reenviar correo"
        topButtonAction={() => (!isLoading && !!formData && reSendCount < 2 ? onResendEmail() : null)}
        topButtonProps={{ isLoading, isDisabled: !formData || isLoading || reSendCount > 2 }}
        bottomButtonText="Ir al Inicio"
        bottomButtonAction={() => (!isLoading ? navigate(storedCity ? `/${storedCity.slug}` : '/') : null)}
        bottomButtonProps={{ isDisabled: isLoading }}
        bottomComponent={bottomComponent}
      />
    </Wrapper>
  )
}

export default SignupConfirmationPage

export const query = graphql`
  query SignupConfirmationPage($signupConfirmationId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $signupConfirmationId } }) {
      edges {
        node {
          title
          description
          keywords
          template {
            ... on ContentfulTmSignup {
              contentful_id
              pageHeading
              subtitle
              infoText
              icon {
                file {
                  url
                }
              }
              backgroundImage {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
